import { languages } from "~/const/overseas"

export const getOverseasRange = (lang: string, priceInTax?: number) => {
  const language = getLang(lang)
  return language === "en" ? calcDollarRange(priceInTax) : calcTaiwanDollarRange(priceInTax)
}

const calcDollarRange = (priceInTax?: number) => {
  const maxUSRate = 160
  const minUSRate = 135
  const maxPrice = priceInTax ? Math.floor(priceInTax / minUSRate) : 0
  const minPrice = priceInTax ? Math.floor(priceInTax / maxUSRate) : 0
  return `$${minPrice} ~ $${maxPrice}`
}

const calcTaiwanDollarRange = (priceInTax?: number) => {
  const maxTaiwanRate = 5.0
  const minTaiwanRate = 4.3
  const maxPrice = priceInTax ? Math.floor(priceInTax / minTaiwanRate) : 0
  const minPrice = priceInTax ? Math.floor(priceInTax / maxTaiwanRate) : 0
  return `NT$${minPrice} ~ NT$${maxPrice}`
}

export const getLang = (lang: string) => {
  const zhTwLanguages = ["zh-TW", "zh-Hant", "zh-Hant-TW"]
  return zhTwLanguages.includes(lang) ? "zh-TW" : "en"
}

export const getOverseasTxtByLang = (lang: string, key: keyof typeof languages) => {
  const language = getLang(lang)
  return languages[key][language]
}
