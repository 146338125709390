export const languages = {
  addToCart: {
    en: "Add to cart",
    "zh-TW": "加入購物車",
  },
  color: {
    en: "Color",
    "zh-TW": "顏色",
  },
  size: {
    en: "Size",
    "zh-TW": "尺寸",
  },
  quantity: {
    en: "Quantity",
    "zh-TW": "數量",
  },
  price: {
    en: "Price",
    "zh-TW": "價格",
  },
  sizeChart: {
    en: "Size Chart",
    "zh-TW": "尺寸表",
  },
  shippingNoteContent: {
    en: "※The shipping fee is approximately 4,500 to 5,000 yen. The actual shipping fee may vary depending on exchange rates and shipping methods. If you are using the bundled packaging service, please check the shipping fee on the payment screen",
    "zh-TW": "※運費約4,500～5,000日圓。實際運費可能因匯率與運輸方式而異。若您使用同捆包裝服務，請在付款頁面查看運費。",
  },
  seeMore: {
    en: "See More",
    "zh-TW": "查看更多",
  },
  orderDetails: {
    en: "Order Details",
    "zh-TW": "訂單詳細信息",
  },
  taxInclude: {
    en: "tax included",
    "zh-TW": "含稅",
  },
  close: {
    en: "close",
    "zh-TW": "關閉",
  },
  amountIncludingTax: {
    en: "Amount including tax",
    "zh-TW": "含稅金額",
  },
  discount: {
    en: "Discount",
    "zh-TW": "折扣",
  },
  totalAmount: {
    en: "Total Amount",
    "zh-TW": "總金額",
  },
  proceedToCheckout: {
    en: "Proceed to Checkout",
    "zh-TW": "付款結算",
  },
  cartIsEmpty: {
    en: "Your cart is currently empty",
    "zh-TW": "您的購物車目前是空的",
  },
  reviews: {
    en: "Reviews",
    "zh-TW": "評價",
  },
  customerReviews: {
    en: "Customer Reviews",
    "zh-TW": "顧客評價",
  },
  averageRatingAmazon: {
    en: "Average Rating in Amazon Japan",
    "zh-TW": "亞馬遜平均評分",
  },
  averageRatingRakuten: {
    en: "Average Rating in Rakuten Japan",
    "zh-TW": "樂天平均評分",
  },
  representativeReviews: {
    en: "Representative Reviews",
    "zh-TW": "代表性評價",
  },
  failedCheckout: {
    en: "Failed to proceed to checkout",
    "zh-TW": "無法繼續結帳",
  },
}
